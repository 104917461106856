.searchDiv {
  height: 0.833333rem;
  width: 7.074074rem;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.searchDiv .ic_search {
  content: "";
  background: url(../../assets/images/ic_search.png) no-repeat center;
  background-size: cover;
  display: inline-block;
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
  width: 0.388889rem;
  height: 0.388889rem;
  position: absolute;
}

.searchDiv .searchInput {
  height: 0.833333rem;
  width: 5.12963rem;
  padding-left: 0.833333rem;
  padding-right: 1.111111rem;
  font-size: 0.388889rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.925926rem;
  line-height: 0.407407rem;
}

.searchDiv .searchInput::-webkit-input-placeholder {
  opacity: 0.4;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: "FlymeRegular";
  font-size: 0.388889rem;
  color: #000000;
  text-align: left;
  line-height: 0.833333rem;
}

.searchDiv .searchInput::-moz-placeholder {
  opacity: 0.4;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: "FlymeRegular";
  font-size: 0.388889rem;
  color: #000000;
  text-align: left;
  line-height: 0.833333rem;
}

.searchDiv .searchInput:-ms-input-placeholder {
  opacity: 0.4;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: "FlymeRegular";
  font-size: 0.388889rem;
  color: #000000;
  text-align: left;
  line-height: 0.833333rem;
}

.searchDiv .searchInput::-ms-input-placeholder {
  opacity: 0.4;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: "FlymeRegular";
  font-size: 0.388889rem;
  color: #000000;
  text-align: left;
  line-height: 0.833333rem;
}

.searchDiv .searchInput::placeholder {
  opacity: 0.4;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: "FlymeRegular";
  font-size: 0.388889rem;
  color: #000000;
  text-align: left;
  line-height: 0.833333rem;
}

.searchDiv .ic_close {
  background: url(../../assets/images/ic_close.png) no-repeat center;
  background-size: cover;
  width: 0.555556rem;
  height: 0.555556rem;
  vertical-align: middle;
  position: absolute;
  top: 0;
  margin: auto;
  right: 0.083333rem;
  bottom: 0;
}