/* ##remfixer: 1080 */

.SearchDetail {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.AppBlockNormalDiv {
  padding-right: 0 !important;
}

.AppBlockNormalDiv .obs {
  padding-right: 0 !important;
}

#downloadApp {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 1.203704rem;
  line-height: 1.203704rem;
  border-radius: 1.203704rem;
  border: 1px solid rgba(252, 91, 35, 0.35);
  margin: 0.240741rem 0 1.111111rem;
  text-align: center;
  background-color: rgba(252, 91, 35, 0.05);
  overflow: hidden;
  color: #000;
  font-size: 0.388889rem;
}

#downloadApp .btn {
  width: 100%;
  height: 100%;
  color: #000;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  font-size: 0.388889rem;
  line-height: 1.203704rem;
}

.Nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0.277778rem 0 0.037037rem 0;
}

.Nav .searchIcon {
  width: 0.666667rem;
  height: 0.666667rem;
  margin: 0 0.277778rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.Nav .searchText {
  opacity: 0.4;
  font-family: Flyme-Medium;
  font-size: 0.444444rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: #000000;
  line-height: 0.527778rem;
  margin: 0 0.444444rem;
}

.Nav .searchClass {
  font-size: 0.444444rem;
  color: #fc5b23;
  opacity: 1;
}

.searchGame .searchImag {
  width: 2.962963rem;
  height: 5.240741rem;
}

.searchGame .GiftDiv {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(255, 199, 31, 0.06);
  border: 0.018519rem solid rgba(255, 196, 18, 0.2);
  border-radius: 0.037037rem;
}

.searchGame .GiftDiv img {
  width: 0.740741rem;
  height: 0.740741rem;
  margin-left: 0.166667rem;
  margin-right: 0.092593rem;
}

.searchGame .GiftDiv span {
  font-family: FlymeRegular;
  font-size: 0.37037rem;
  color: rgba(0, 0, 0, 0.9);
  letter-spacing: 0;
  line-height: 0.388889rem;
}

.searchGame .ImgDiv /deep/ .block_list {
  margin-left: -0.444444rem;
}

.notSearch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.notSearch img {
  width: 4.444444rem;
  height: 4.444444rem;
}

.notSearch p {
  opacity: 0.4;
  font-family: "FlymeRegular";
  font-size: 0.388889rem;
  color: #000000;
}

.tagBlockDiv .block_list {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.tagBlockDiv .tagBlock {
  position: relative;
  line-height: 0.666667rem;
  border: 0.018519rem solid rgba(0, 0, 0, 0.15);
  border-radius: 0.055556rem;
  margin: 0.111111rem;
}

.tagBlockDiv .tagBlock span {
  padding: 0.12963rem 0.277778rem 0.148148rem;
  width: 100%;
  height: 100%;
  display: inline-block;
  opacity: 0.6;
  font-family: FlymeRegular;
  font-size: 0.388889rem;
  line-height: 0.666667rem;
  color: #000000;
  letter-spacing: 0;
}

.HotBlockDiv /deep/ .block_head-title {
  opacity: 0.4;
  font-family: Flyme-Medium;
  font-size: 0.333333rem;
  color: #000000;
  text-align: left;
}

.HotBlockDiv /deep/ .block_head-more {
  font-family: Flyme-Medium;
  font-size: 0.333333rem;
  color: #fc5b23;
  letter-spacing: 0;
  text-align: center;
}

.tagBlockDiv /deep/ .block_head-more {
  font-family: Flyme-Medium;
  font-size: 0.333333rem;
  color: #fc5b23;
  text-align: right;
}

.tagBlockDiv /deep/ .block_head-title {
  opacity: 0.4;
  font-family: Flyme-Medium;
  font-size: 0.333333rem;
  color: #000000;
  text-align: left;
}

.HotAppSmall {
  margin-right: 0.814815rem !important;
}

.searchClass {
  color: #fc5b23;
  opacity: 1;
}